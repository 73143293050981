.stat {
  width: calc((100% - 5px) / 3);
  box-sizing: border-box;

  gap: 0.5rem;
  padding: 1.5rem 3rem;
}

.divider {
  width: 4px;

  margin: 2rem 0rem;

  border-radius: 2px;
  background-color: white;
  scale: 1.25;
}

.slider-container {
  position: relative;
  width: 100%;
}

.slide {
  position: absolute;
  width: 75%;

  transition: all 0.2s ease;

  svg {
    height: 36px;
    width: 36px;
  }
}

.slide::before {
  content: "";
  position: absolute;
  left: 0;

  width: 10px;
  height: 75%;

  translate: -50% 0%;

  border-radius: 10px;

  background-color: var(--main-blue);
}

.slide.active {
  z-index: 3;
}

.slide.inactive {
  z-index: 1;
}

.social-icon {
  cursor: pointer;
  width: 35px;
  height: 35px;
  svg {
    transition: fill 0.3s ease-in-out;
    width: 35px;
    height: 35px;
  }
}

.social-icon:hover {
  path:nth-child(1) {
    fill: #1a86cb !important;
  }
  rect {
    fill: #1a86cb !important;
  }
}

.contact-us {
  background-image: url("./assets/pngs/map.png");
  background-position: 100% 25%;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: calc(var(--contact-form-half-height) / 1.5);
  padding-bottom: var(--contact-form-half-height);
  position: relative;
  z-index: 1;
}

.mask {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.75;
  z-index: -1;
  display: none;
}

@media (max-width: 1024px) {
  .contact-us {
    padding-top: calc(var(--contact-form-half-height) / 2) !important;
    padding-bottom: calc(var(--contact-form-half-height)) !important;
  }
}

@media (max-width: 768px) {
  .mask {
    background-color: rgba(0, 0, 0, 0.65);
  }
}

@media (max-width: 920px) {
  .mask {
    display: block;
  }
  .contact-us {
    padding: 12rem 3rem 17.5rem 3rem;
  }
}

@media (max-width: 768px) {
  .divider:nth-child(4) {
    display: none;
  }

  .stat:nth-child(5) {
    width: 100%;
  }
}

@media (max-width: 551px) {
  .stats {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .divider:nth-child(4) {
    display: block;
  }

  .divider {
    height: 5px;
    width: 65%;
    margin: auto;
  }

  .stat {
    width: 100%;
  }
}
